import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Content, { HTMLContent } from "../components/Content";
import PageWrapper from "../components/PageWrapper";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title, Text, Box, Badge } from "../components/Core";
import { FormattedMessage, useIntl } from "react-intl";
import CTA from "../sections/jobs/CTA";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const BadgePost = ({ children }) => (
  <Badge
    mr={3}
    mb={3}
    bg="#eae9f2"
    color="#696871"
    fontSize="16px"
    px={3}
    py={2}
  >
    {children}
  </Badge>
);

export const JobPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  featuredimage,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  const intl = useIntl();

  return (
    <section className="section">
      {helmet}
      <Section className="pb-5">
        <div className="pt-3"></div>
        <Container>
          <Row className="justify-content-center text-center">
            <Col lg="12">
              <Title variant="hero">{title}</Title>
              <Box className="d-flex justify-content-center">
                <Text mr={3}>{description}</Text>
              </Box>
              {tags && tags.length ? (
                <div style={{ marginTop: `1rem` }}>
                  <ul className="taglist" style={{ paddingInlineStart: 0 }}>
                    {tags.map((tag, i) => (
                      // <Link to={`/tags/${kebabCase(tag)}/`} key={i}>
                      <BadgePost>{tag}</BadgePost>
                      // </Link>
                    ))}
                  </ul>
                </div>
              ) : null}
            </Col>
            <Col lg="12">
              <PreviewCompatibleImage
                imageInfo={{
                  image: featuredimage,
                }}
                style={{ borderRadius: 8, height: 300 }}
              />
            </Col>
          </Row>
        </Container>
      </Section>
      <div className="container content">
        <Container style={{ marginBottom: `4rem` }}>
          <PostContent content={content} />
        </Container>
      </div>
      <CTA
        collaborate={intl.formatMessage({
          id: "jobs.questions",
          defaultMessage: "Questions?",
        })}
        collaborate_description={intl.formatMessage({
          id: "jobs.reachOut",
          defaultMessage: "Get in touch with Abel",
        })}
        collaborate_cta={intl.formatMessage({
          id: "jobs.contact_email",
          defaultMessage: "abel@tournify.nl",
        })}
        collaborate_cta2={intl.formatMessage({
          id: "jobs.contact_phone",
          defaultMessage: "+31 6 81 52 59 78",
        })}
      />
    </section>
  );
};

JobPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const JobPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <PageWrapper footerDark>
      <JobPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        featuredimage={post.frontmatter.featuredimage}
        helmet={
          <Helmet titleTemplate="%s | Job">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              property="og:image"
              content={post.frontmatter.featuredimage.childImageSharp.fluid.src}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </PageWrapper>
  );
};

JobPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default JobPost;

export const pageQuery = graphql`
  query JobPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tags
      }
    }
  }
`;
