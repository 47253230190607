import React from 'react'
import styled from "styled-components";
import PropTypes from 'prop-types'

const ContentText = styled.div`
  & > p {
    margin-bottom: 1rem;
    font-size: 21px;
  }
  & > ul {
    font-size: 18px;
    font-weight: 300;
  }
  & > h1 {
    margin-top: 3rem;
  }
  & > h2 {
    margin-top: 3rem;
  }
  & > h3 {
    margin-top: 3rem;
  }
  & > h4 {
    margin-top: 3rem;
  }
  & > h5 {
    margin-top: 2rem;
  }
`

export const HTMLContent = ({ content, className }) => (
  <ContentText className={className} dangerouslySetInnerHTML={{ __html: content }} />
)

const Content = ({ content, className }) => (
  <ContentText className={className}>{content}</ContentText>
)

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = Content.propTypes

export default Content
